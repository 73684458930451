import axios from 'axios'
import store from '../store'
// 富文本懒加载图片替换
export function replaceAllImg(html) {

    var newContent = html.replace(/<img[^>]*>/gi, function (str, capture) {

        //console.log('==', str);

        var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

        var url = str.match(srcReg);


        var mat = str.replace(/src=\"(.*)\"/gi, 'data-src=' + url[1]);

        return mat;

    });
    return newContent;
}

// 通用下载方法
export function download(url, params = {}, fileName) {
    axios({
            method: 'get',
            url: process.env.VUE_APP_BASE_API + url, // 这里是后端的接口地址
            params,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': store.getters.userInfo.token
            },
        })
        .then(res => {
            // 假设res表示后端发来的流数据
            let blob = new Blob([res.data], {
                    type: '.xlsx'
                }),
                link = document.createElement("a"),
                href = window.URL.createObjectURL(blob);
            link.href = href;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
}